<template>
  <div class="img-wrapper lazyLoad">
    <div><img src="../../../../static/images/content1.jpg"></div>
    <div v-for="img in imgList" class="img-content">
      <img v-lazy="img.url">
    </div>
    <div><img src="../../../../static/images/content10.jpg"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      imgList: [
        //     {
        //   url:require("../../../static/images/content1.jpg"),
        // },
        {
          url: require("../../../../static/images/content2.jpg"),
        }, {
          url: require("../../../../static/images/content3.jpg"),
        }, {
          url: require("../../../../static/images/content4.jpg"),
        }, {
          url: require("../../../../static/images/content5.jpg"),
        }, {
          url: require("../../../../static/images/content6.jpg"),
        }, {
          url: require("../../../../static/images/content7.jpg"),
        }, {
          url: require("../../../../static/images/content8.jpg"),
        }, {
          url: require("../../../../static/images/content9.jpg"),
        },
        // {
        //   url: require("../../../static/images/content10.jpg"),
        // },
      ]
    }
  }
}
</script>
<style lang="less">
.img-wrapper {
  img {
    width: 100%;
    height: 100%;
  }
}

.img-content {
  width: 1200px;
  margin: 0 auto;
}

img[lazy="loading"] {
  display: block;
  margin: 0 auto;
}
</style>
